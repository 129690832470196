@charset "UTF-8";
/** 兼容IE */
.course-item {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eee; }
  .course-item:first-child + .course-item + .course-item + .course-item {
    margin-right: 0; }
    .course-item:first-child + .course-item + .course-item + .course-item + .course-item + .course-item + .course-item + .course-item {
      margin-right: 0; }

.course-cover-bg,
.course-cover {
  width: 277px;
  height: 166px; }

.pg-main .act-btns {
  z-index: 130; }

.pg-main .act-title {
  filter: alpha(opacity=50);
  background: #000; }
